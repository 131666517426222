.heroImage{
    background-image: url(../src/Assests/testbackdrop2.jpg);
}

.heroLeft{
    background-image: url(../src/Assests/PandC.svg);
}

.heroRight{
    background-image: url(../src/Assests/HeroImage2.jpg);
}